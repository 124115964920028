import { NgClass, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { AppPath } from '@shared/routes/app-routing.enum';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';

@Component({
    selector: 'jsdaddy-not-found',
    standalone: true,
    imports: [AssetPipe, SectionWrapperComponent, ButtonComponent, NgOptimizedImage, NgClass],
    templateUrl: './not-found.component.html',
    styleUrl: './not-found.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundComponent {
    public readonly buttonPrimaryYellow = ButtonType.PrimaryYellow;
    public readonly assetPathShared = SharedAssetPath.ROOT;
    public readonly homePath = `/${AppPath.EMPTY}`;
}
