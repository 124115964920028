<div [style.height]="half() ? this.height + 'px' : '100%'">
    <div
        [style.--bg-image]="
            image()?.toString() || '1'
                | asset: 'shared/show-cases/' + showCase()?.assetsFolder : true
                | imageUrl
        "
        [ngClass]="[showCase()?.type, 'column pos-rel inside']">
        <img
            width="2560"
            height="1042"
            [alt]="showCase()?.type"
            #imageRef
            [ngSrc]="showCase()?.type | frame: half() && showCase()?.type === webShowCaseType" />
    </div>
</div>
