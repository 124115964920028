import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import {
    ArrowButtonDirectionEnum,
    ArrowButtonTypeEnum,
} from '@shared/arrow-button/arrow-button.enum';
import { NgClass, NgOptimizedImage } from '@angular/common';
import { TransformRotatePipe } from '@shared/arrow-button/transform-rotate/transform-rotate.pipe';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { SharedAssetPath } from '@shared/asset/asset.path';

@Component({
    selector: 'jsdaddy-arrow-button',
    standalone: true,
    imports: [TransformRotatePipe, AssetPipe, NgOptimizedImage, NgClass],
    templateUrl: './arrow-button.component.html',
    styleUrl: './arrow-button.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArrowButtonComponent {
    public direction = input<ArrowButtonDirectionEnum>(ArrowButtonDirectionEnum.FORWARD);
    public type = input<ArrowButtonTypeEnum>(ArrowButtonTypeEnum.REGULAR);
    public rotateDeg = input(0);
    public enabled = input(true);

    public assetPath = SharedAssetPath.ARROW_BUTTON;
}
