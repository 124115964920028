import { ChangeDetectionStrategy, Component, ViewEncapsulation, inject } from '@angular/core';
import { DevelopmentProcessComponent } from '@shared/development-process/development-process.component';
import { OurClientsSayComponent } from '@shared/our-clients-say/our-clients-say.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { ShowCase } from '@shared/show-cases/show-cases.type';
import { ShowCasesService } from '@shared/show-cases/show-cases.service';
import { TechnologiesSliderComponent } from '@shared/technologies-slider/technologies-slider.component';
import { CaseHeaderComponent } from './case-header/case-header.component';
import { CaseOverviewComponent } from './case-overview/case-overview.component';
import { CaseStepsComponent } from './case-steps/case-steps.component';
import { MetaTagsService } from '@shared/meta-tags/meta-tags.service';
import { SingleContentComponent } from '@shared/meta-tags/meta-tags.component';

@Component({
    selector: 'jsdaddy-case',
    standalone: true,
    imports: [
        SectionWrapperComponent,
        CaseHeaderComponent,
        CaseStepsComponent,
        CaseOverviewComponent,
        TechnologiesSliderComponent,
        DevelopmentProcessComponent,
        OurClientsSayComponent,
    ],
    providers: [ShowCasesService, MetaTagsService],
    templateUrl: './case.component.html',
    styleUrl: './case.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseComponent extends SingleContentComponent<ShowCase> {
    private readonly showCasesService = inject(ShowCasesService);

    public contentJson$ = this.showCasesService.getShowCases();
}
