import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { ButtonType } from '@shared/button/button.enums';
import { ButtonPipe } from './button.pipe';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';
import { NgClass } from '@angular/common';

@Component({
    selector: 'jsdaddy-button[title]',
    standalone: true,
    imports: [RouterLinkWithHref, ButtonPipe, AnchorLabelPipe, NgClass],
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
    public link = input<string[] | string | undefined>();
    public title = input.required<string>();
    public justifyContent = input('center');

    public type = input<ButtonType>(ButtonType.Primary);
}
