import { NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {
    booleanAttribute,
    ChangeDetectionStrategy,
    Component,
    input,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ArrowButtonComponent } from '@shared/arrow-button/arrow-button.component';
import {
    ArrowButtonDirectionEnum,
    ArrowButtonTypeEnum,
} from '@shared/arrow-button/arrow-button.enum';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { BorderButtonComponent } from '@shared/border-button/border-button.component';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { ChipComponent } from '@libraries/chip/chip.component';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { AppPath } from '@shared/routes/app-routing.enum';
import { ImageWithFrameComponent } from '@shared/show-cases/image-with-frame/image-with-frame.component';
import { ShowCase } from '@shared/show-cases/show-cases.type';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { ShowCaseIsMobilePipe } from '../show-case-mobile/show-case-is-mobile.pipe';
import { ShowCasesListOthersPipe } from './show-cases-list-others.pipe';
import { ShowCaseTheme } from './show-cases-list.enum';
import { ShowCaseThemeScheme } from './show-cases-list.type';
import { RouterLinkWithHref } from '@angular/router';
import { AnchorLabelPipe } from '@libraries/anchor/anchor-label.pipe';

@Component({
    selector: 'jsdaddy-show-cases-list',
    standalone: true,
    imports: [
        RouterLinkWithHref,
        NgTemplateOutlet,
        HeaderTitleComponent,
        ButtonComponent,
        AssetPipe,
        ShowCaseIsMobilePipe,
        ShowCasesListOthersPipe,
        ArrowButtonComponent,
        ImageWithFrameComponent,
        ChipComponent,
        BorderButtonComponent,
        NgOptimizedImage,
        AnchorLabelPipe,
        NgClass,
    ],
    templateUrl: './show-cases-list.component.html',
    styleUrl: './show-cases-list.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowCasesListComponent implements OnInit {
    public readonly buttonType = ButtonType.PrimaryYellow;
    public readonly arrowButtonDirection: ArrowButtonDirectionEnum = ArrowButtonDirectionEnum.BACK;
    public readonly assetPath = SharedAssetPath.SHOW_CASES;
    public readonly contactUs = `/${AppPath.CONTACT_US}`;
    public themeScheme!: ShowCaseThemeScheme;

    public showCases = input([] as ShowCase[], {
        transform: (value: ShowCase[] | undefined) => {
            if (!value) {
                return [];
            }
            if (this.forMainPage()) {
                return value.slice(0, 3);
            }
            return value;
        },
    });

    public isSubtitleMoreThanText = input(false, { transform: booleanAttribute });
    public forMainPage = input(false, { transform: booleanAttribute });
    public showCaseTheme = input<ShowCaseTheme>(ShowCaseTheme.BLACK);
    public title = input<string | undefined>('');
    public subtitle = input('');
    public showCasesLength = 0;

    public readonly caseStudies = `/${AppPath.CASE_STUDIES}`;

    public ngOnInit(): void {
        if (this.showCaseTheme() === ShowCaseTheme.BLACK) {
            this.themeScheme = {
                needShowAllCases: true,
                chipBgColor: 'rgba(248, 248, 248, 0.05)',
                textColorStyle: 'full-white',
                dividerColorStyle: 'bg-full-white',
                needStars: true,
                isTitleBlack: false,
                arrowButtonType: ArrowButtonTypeEnum.LINK,
            };
            return;
        }
        this.themeScheme = {
            needShowAllCases: false,
            chipBgColor: 'rgba(0,0,0,0.05)',
            textColorStyle: 'black',
            dividerColorStyle: 'bg-black',
            needStars: false,
            isTitleBlack: true,
            arrowButtonType: ArrowButtonTypeEnum.REGULAR,
        };
    }
}
