import {
    booleanAttribute,
    ChangeDetectionStrategy,
    Component,
    input,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'jsdaddy-section-wrapper',
    standalone: true,
    templateUrl: './section-wrapper.component.html',
    styleUrl: './section-wrapper.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionWrapperComponent {
    public black = input(false, {
        transform: booleanAttribute,
    });
    public noize = input(false, {
        transform: booleanAttribute,
    });

    public overflowYStyle = input('hidden');
    public overflowXStyle = input('hidden');
    public minHeight = input('auto');
    public maxHeight = input('auto');

    public get styleList(): string {
        const styleProps = [
            { prop: this.black(), className: 'bg-black' },
            { prop: this.noize(), className: 'noize' },
        ];

        return styleProps
            .filter(({ prop }) => prop)
            .map(({ className }) => className)
            .join(' ');
    }
}
