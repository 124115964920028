import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewEncapsulation,
    inject,
    DestroyRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { ChipComponent } from '@libraries/chip/chip.component';
import { HeaderImageComponent } from '@shared/header-image/header-image.component';
import { LetsDoWorkComponent } from '@shared/lets-do-work/lets-do-work.component';
import { OurClientsSayComponent } from '@shared/our-clients-say/our-clients-say.component';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { ShowCasesListComponent } from '@shared/show-cases-list/show-cases-list.component';
import { ShowCaseTheme } from '@shared/show-cases-list/show-cases-list.enum';
import { FilterItem } from './case-studies.type';
import { CaseStudiesService } from './case-studies.service';
import { BorderButtonComponent } from '@shared/border-button/border-button.component';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ShowCasesService } from '@shared/show-cases/show-cases.service';

@Component({
    selector: 'jsdaddy-case-studies',
    standalone: true,
    imports: [
        NgTemplateOutlet,
        AssetPipe,
        SectionWrapperComponent,
        ShowCasesListComponent,
        LetsDoWorkComponent,
        ChipComponent,
        HeaderImageComponent,
        OurClientsSayComponent,
        ButtonComponent,
        BorderButtonComponent,
        NgClass,
    ],
    providers: [CaseStudiesService, ShowCasesService],
    templateUrl: './case-studies.component.html',
    styleUrl: './case-studies.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseStudiesComponent implements OnInit {
    public readonly showCaseThemeWhite = ShowCaseTheme.WHITE;
    public readonly buttonTypeYellow = ButtonType.PrimaryYellow;
    public readonly assetPath = 'content/case-studies';
    public isFilterOpen = false;

    public readonly mobileFilterProps = {
        textColor: 'full-white',
        chipBgColor: 'rgba(255, 255, 255, 0.05)',
    };
    public readonly filterProps = {
        chipBgColor: 'rgba(0,0,0,0.05)',
        mainCssClass: 'inline-flex filter-list',
        textColor: 'black',
    };

    private readonly route = inject(ActivatedRoute);
    private readonly service = inject(CaseStudiesService);
    private readonly destroyRef = inject(DestroyRef);

    public left = toSignal(this.service.left$);
    public readonly showCases = toSignal(this.service.showCases$);
    public readonly isFilterExist = toSignal(this.service.isFilterExist$);
    public readonly selectedCategoryTitle = toSignal(this.service.selectedCategoryTitle$);
    public readonly hasMoreCases = toSignal(this.service.hasMoreCases$);
    public readonly showAllTechnologiesSignal = toSignal(this.service.showAllTechnologies$);
    public readonly categories = toSignal(this.service.categories$);
    public readonly technologiesByCategory = toSignal(this.service.technologiesByCategory$);

    public ngOnInit(): void {
        this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params) => {
            this.service.handleParams(params, this.categories());
        });
    }

    public clearFilters(): void {
        this.service.clearFilters();
    }

    public selectCategory(filterItem: FilterItem): void {
        this.service.selectCategory(filterItem);
    }

    public showAllTechnologies(): void {
        this.service.showAllTechnologies();
    }
    public selectTechnology(filterItem: FilterItem): void {
        this.service.selectTechnology(filterItem);
    }

    public showMore(): void {
        this.service.showMore();
    }

    public toggleFilter(): void {
        this.isFilterOpen = !this.isFilterOpen;
    }
}
