<section class="column">
    <jsdaddy-grid class="grid-top" />

    <div class="row jc-sb ai-c header">
        <jsdaddy-header-title
            black
            isSubtitleMoreThanText
            isTitleRightSide
            title="CompanyID"
            subtitle="Streamline, Simplify, Succeed"
            needStar />
        <jsdaddy-button
            [link]="companyIdLandingPricing"
            class="view-price-list-btn"
            [type]="buttonTypePrimary"
            title="View Price List" />
    </div>
    <div class="row company-id-block">
        <div class="column left-side">
            <img
                alt="company-id-logo"
                class="logo"
                width="220"
                height="40"
                [ngSrc]="'logo' | asset: assetPath" />
            <span class="streamline-h3 h3-text">Streamline, Simplify, Succeed</span>
            <span class="streamline-span span">Streamline Business Tracking and Reporting</span>
            <jsdaddy-button
                class="become-a-client"
                [link]="companyIdLandingContactUs"
                [type]="buttonTypePrimaryYellow"
                title="Become A Client!" />
        </div>
        <div class="right-side pos-rel row">
            <img
                alt="screens"
                class="screens"
                width="650"
                height="536"
                [ngSrc]="'screens' | asset: assetPath : true" />
        </div>
    </div>
</section>
