import { DOCUMENT, isPlatformServer, NgClass, ViewportScroller } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    inject,
    OnInit,
    // type-coverage:ignore-next-line
    PLATFORM_ID,
    signal,
    viewChild,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ResolveEnd, Router, RouterOutlet, Scroll } from '@angular/router';
import { NotFoundComponent } from '@content/not-found/not-found.component';
import { HeaderComponent } from '@header/header.component';
import { ReadyForDiscussComponent } from '@header/ready-for-discuss/ready-for-discuss.component';
import { RightContentPipe } from '@header/ready-for-discuss/right-content.pipe';
import { VisibilityPipe } from '@shared/visibility/visibility.pipe';
import { filter, map, switchMap, tap } from 'rxjs';
import { FooterComponent } from './footer/footer.component';
import { ReadyForDiscussService } from '@header/ready-for-discuss/ready-for-discuss.service';
import { BreadcrumbService } from '@shared/breadcrumbs/breadcrumbs.service';
import { CurrentRouteService } from '@shared/current-route/current-route.service';
import { RecaptchaService } from '@shared/recaptcha/recaptcha.service';
import { ResizeService } from '@shared/resize/resize.service';

@Component({
    selector: 'jsdaddy-root',
    standalone: true,
    imports: [
        RouterOutlet,
        HeaderComponent,
        FooterComponent,
        RouterOutlet,
        ReadyForDiscussComponent,
        RightContentPipe,
        VisibilityPipe,
        NgClass,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {
    private readonly currentRouteService = inject(CurrentRouteService);
    private readonly breadcrumbService = inject(BreadcrumbService);
    private readonly resizeService = inject(ResizeService);
    private readonly readyForDiscussService = inject(ReadyForDiscussService);
    private readonly platformId = inject<string>(PLATFORM_ID);
    private readonly recaptchaService = inject(RecaptchaService);
    private readonly router = inject(Router);
    private readonly document = inject(DOCUMENT);
    private readonly destroyRef = inject(DestroyRef);
    private readonly viewportScroller = inject(ViewportScroller);

    private readonly oldUrl = signal<string>('');

    public needCrankle = toSignal(
        this.currentRouteService.currentRoute$.pipe(
            map((route: ActivatedRoute) => !(route.component === NotFoundComponent))
        )
    );
    public innerWidth = signal(0);

    public readyForDiscussContainer = viewChild<string, ElementRef<HTMLDivElement>>(
        'readyForDiscussContainer',
        { read: ElementRef }
    );

    public isShowReadyForDiscuss = toSignal(this.readyForDiscussService.isShowReadyForDiscuss$);
    public readyForDiscussClass = toSignal(this.readyForDiscussService.readyForDiscussClass$);

    public ngOnInit(): void {
        if (!isPlatformServer(this.platformId)) {
            this.router.events
                .pipe(
                    filter((event): event is Scroll => event instanceof Scroll),
                    tap((e: Scroll) => {
                        const url = e.routerEvent.url;
                        const currentBaseUrl = url.substring(0, url.indexOf('?')) || url;
                        const position = e.position;

                        if (position) {
                            this.viewportScroller.scrollToPosition(position);
                        }
                        if (currentBaseUrl !== this.oldUrl() && !position) {
                            this.viewportScroller.scrollToPosition([0, 0]);
                        }
                        this.oldUrl.set(currentBaseUrl);
                    }),
                    takeUntilDestroyed(this.destroyRef)
                )
                .subscribe();
        }

        this.breadcrumbService.getBreadcrumbs();
        if (isPlatformServer(this.platformId)) {
            return;
        }
        const body: HTMLBodyElement | null = this.document.querySelector('body');
        if (body) {
            this.resizeService
                .createResizeSequence(body)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((width) => {
                    this.innerWidth.set(width);
                });
        }
        this.setUpRecaptcha();
    }

    public ngAfterViewInit(): void {
        this.readyForDiscussService.subscribeToIsShowReadyForDiscuss(
            this.readyForDiscussContainer()
        );
    }

    private setUpRecaptcha(): void {
        this.router.events
            .pipe(
                filter((event): event is ResolveEnd => event instanceof ResolveEnd),
                switchMap(() => this.recaptchaService.recaptcha(`navigation`)),
                takeUntilDestroyed(this.destroyRef)
            )
            .subscribe();
    }
}
