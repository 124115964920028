<div class="list span">
    <ul>
        @for (service of servicesJson; track service.title) {
            <li>
                <a
                    [attr.aria-label]="service.title | anchorLabel"
                    class="span"
                    [routerLink]="[services, service.slug]"
                    (click)="closeDropdownEmit()"
                    >{{ service.title }}</a
                >
            </li>
        }
    </ul>
</div>
<div [style.margin-right.px]="currentMarginRight()" class="discuss">
    <div class="question">have questions?</div>
    <div class="discuss-your-project">Let's<br />Discuss Your<br />Project!</div>
    <jsdaddy-border-button
        [link]="contactUs"
        title="Want to Discuss!"
        (click)="closeDropdownEmit()" />
</div>
