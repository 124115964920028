import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { ChipComponent } from '@libraries/chip/chip.component';
import { ButtonComponent } from '@shared/button/button.component';
import { ButtonType } from '@shared/button/button.enums';
import { DevelopmentProcessComponent } from '@shared/development-process/development-process.component';
import { HeaderImageComponent } from '@shared/header-image/header-image.component';
import { IndentPipe } from '@shared/indent/indent.pipe';
import { OurClientsSayComponent } from '@shared/our-clients-say/our-clients-say.component';
import { AppPath } from '@shared/routes/app-routing.enum';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { ServiceProjectsComponent } from './service-projects/service-projects.component';
import { ServiceTechnologiesComponent } from './service-technologies/service-technologies.component';
import { ServiceAssetPath } from './service.path';
import { MetaTagsService } from '@shared/meta-tags/meta-tags.service';
import { of } from 'rxjs';
import { Service } from '@content/home/services/services.type';
import servicesJson from 'src/assets/json/services.json';
import { SingleContentComponent } from '@shared/meta-tags/meta-tags.component';

@Component({
    selector: 'jsdaddy-service',
    templateUrl: './service.component.html',
    styleUrl: './service.component.scss',
    standalone: true,
    imports: [
        AssetPipe,
        ServiceTechnologiesComponent,
        ServiceProjectsComponent,
        DevelopmentProcessComponent,
        OurClientsSayComponent,
        SectionWrapperComponent,
        HeaderImageComponent,
        ChipComponent,
        ButtonComponent,
        IndentPipe,
    ],
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [MetaTagsService],
})
export class ServiceComponent extends SingleContentComponent<Service> {
    public readonly contactUsPath = `/${AppPath.CONTACT_US}`;
    public readonly assetPath = ServiceAssetPath.ROOT;
    public readonly primaryYellow = ButtonType.PrimaryYellow;

    public contentJson$ = of(servicesJson);
}
