<header class="pos-rel">
    <div
        class="plus-top hide-tablet hide-mobile"
        [style.background-image]="'obj-plus' | asset: assetPath | imageUrl"></div>
    @if (!(showCase()?.type | showCaseIsMobile)) {
        @if (showCase()?.images?.[0]) {
            <div
                class="img-header pos-abs"
                [style.background-image]="
                    showCase()?.images?.[0]?.toString()
                        | asset: assetPathSharedShowCases + '/' + showCase()?.assetsFolder : true
                        | imageUrl
                "></div>
        }
        <ng-container *ngTemplateOutlet="headerContent" />
    }
    @if (showCase()?.images?.[0] && (showCase()?.type | showCaseIsMobile)) {
        <div
            class="img-header"
            [style.background-image]="
                showCase()?.images?.[0]?.toString()
                    | asset: assetPathSharedShowCases + '/' + showCase()?.assetsFolder : true
                    | imageUrl
            ">
            <ng-container *ngTemplateOutlet="headerContent" />
        </div>
    }
</header>

<ng-template #headerContent>
    <jsdaddy-section-wrapper>
        <div class="column content">
            <jsdaddy-breadcrumbs />
            <div [ngClass]="['row jc-sb inside', 'inside-' + showCase()?.type]">
                <div class="column descrition">
                    <jsdaddy-header-title
                        class="row"
                        needStar
                        subtitle="case"
                        [title]="showCase()?.title" />
                    <div class="row chips full-white">
                        @for (solution of showCase()?.solutions || []; track solution) {
                            <jsdaddy-chip
                                chipBgColor="rgba(255, 255, 255, 0.25)"
                                class="row"
                                [chip]="solution" />
                        }
                    </div>
                    <span class="descrition-text text full-white">{{
                        showCase()?.shortDescription
                    }}</span>
                </div>
                <jsdaddy-image-with-frame
                    [class]="'frame-center-' + showCase()?.type"
                    [half]="showCase()?.type | showCaseIsMobile"
                    [showCase]="showCase()" />
                <div [ngClass]="['column logo right-block', showCase()?.type]">
                    <div class="column logo-link">
                        <div
                            class="ais-end logo"
                            [style.background-image]="
                                'logo'
                                    | asset
                                        : assetPathSharedShowCases + '/' + showCase()?.assetsFolder
                                        : true
                                    | imageUrl
                            "></div>
                        @if (showCase()?.webLink) {
                            <a
                                [attr.aria-label]="showCase()?.title | anchorLabel"
                                class="row pointer visit ais-end"
                                [href]="showCase()?.webLink"
                                target="_blank">
                                <span class="text visit-text full-white">Visit Solution</span>
                                <img
                                    alt="Visit case arrow"
                                    class="arrow-top-right"
                                    width="24"
                                    height="24"
                                    [ngSrc]="'arrow-top-right' | asset: assetPathShared" />
                            </a>
                        }
                    </div>
                    @if (showCase()?.androidLink || showCase()?.iosLink) {
                        <jsdaddy-case-header-shop [showCase]="showCase()" />
                    }
                    <jsdaddy-image-with-frame
                        [class]="'ais-end frame-right-' + showCase()?.type"
                        [half]="showCase()?.type | showCaseIsMobile"
                        [showCase]="showCase()" />
                </div>
            </div>
        </div> </jsdaddy-section-wrapper
></ng-template>
