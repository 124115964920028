import { NgClass, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { SharedAssetPath } from '@shared/asset/asset.path';
import { AssetPipe } from '@libraries/asset/asset.pipe';
import { BorderButtonComponent } from '@shared/border-button/border-button.component';
import { AppPath } from '@shared/routes/app-routing.enum';
import { ShowCase } from '@shared/show-cases/show-cases.type';
import { CaseAssetPath } from '../case.path';

@Component({
    selector: 'jsdaddy-case-steps[showCase]',
    standalone: true,
    imports: [NgTemplateOutlet, AssetPipe, BorderButtonComponent, NgOptimizedImage, NgClass],
    templateUrl: './case-steps.component.html',
    styleUrl: './case-steps.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CaseStepsComponent {
    public showCase = input.required<ShowCase | undefined>();
    public assetPath = CaseAssetPath.STEPS;
    public assetPathShared = SharedAssetPath.ROOT;

    public readonly contactUs = `/${AppPath.CONTACT_US}`;
}
