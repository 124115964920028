<jsdaddy-section-wrapper>
    <section>
        <jsdaddy-breadcrumbs isBlack />
        <jsdaddy-grid class="grid-plus" />
        <div class="row">
            <div class="column headline">
                <p class="header orange-yellow">Ready to discuss</p>
                <p class="header">your project ?</p>
            </div>
            <img
                width="100"
                height="100"
                [ngSrc]="'head' | asset: assetPathShared"
                class="head"
                alt="Head with glasses" />
        </div>
        <div class="info row">
            @for (item of emailItems; track item.title) {
                <div class="column">
                    <span class="h5-text title uppercase ls-25">{{ item.title }}</span>
                    <a aria-label="Send email" class="h2-text text" [href]="item.email | email">
                        {{ item.email }}
                    </a>
                </div>
            }
        </div>
        <div class="location-container row">
            @for (location of locationItems; track location.title) {
                <div class="location row">
                    <img
                        width="22"
                        height="28"
                        [ngSrc]="'marker' | asset: assetPath"
                        alt="Location marker" />
                    <div class="column">
                        <a
                            [href]="
                                'https://www.google.com/maps/search/?api=1&query=' + location.title
                            "
                            target="_blank"
                            [attr.aria-label]="'Open Google Maps for ' + location.title"
                            ><h4>{{ location.title }}</h4></a
                        >
                        <span>{{ currentTime() | date: 'HH:mm:ss (z)' : location.timeZone }}</span>
                    </div>
                </div>
            }
        </div>

        <div class="form column">
            <div class="row description ai-c">
                <div class="circle-arrow pos-rel">
                    <img
                        class="arrow"
                        width="18"
                        height="18"
                        [ngSrc]="'arrow-right' | asset: assetPath"
                        alt="Arrow" />
                    <div class="circle"></div>
                </div>

                <p class="text">
                    If you have questions or need our services please use the contact form and we'll
                    get back to you shortly.
                </p>
            </div>
            <div>
                <jsdaddy-contact-form withSteps />
            </div>
        </div>
    </section>
</jsdaddy-section-wrapper>
