@if (!link()) {
    <button [ngClass]="[type(), 'text']" [style.justify-content]="justifyContent()">
        {{ title() }}
    </button>
} @else if (link()! | button) {
    <a
        [attr.aria-label]="title() | anchorLabel"
        target="_blank"
        [ngClass]="[type(), 'text']"
        [href]="link()">
        {{ title() }}
    </a>
} @else if (!(link()! | button)) {
    <a [attr.aria-label]="title() | anchorLabel" [ngClass]="[type(), 'text']" [routerLink]="link()">
        {{ title() }}
    </a>
}
