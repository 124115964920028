<jsdaddy-section-wrapper black noize minHeight="100dvh" maxHeight="100dvh">
    <div #menu class="wrapper">
        <div class="content">
            <div class="bg-jsdaddys">
                <img
                    width="505"
                    height="222"
                    [ngSrc]="'jsdaddys' | asset: assetPath"
                    alt="JSDaddy`s" />
            </div>
            <div class="beard-mustashes" aria-label="Close">
                <img
                    width="745"
                    height="882"
                    [ngSrc]="'beard-mustashes' | asset: assetPath"
                    alt="Beard Mustashes" />
            </div>
            <div class="menu">
                <button (click)="close()" class="close-btn" aria-label="Close">
                    <img
                        width="27"
                        height="27"
                        [ngSrc]="'close-btn' | asset: assetPathShared"
                        alt="Close button" />
                </button>
                <div class="navigation">
                    <ul>
                        @for (item of navigation(); track item.text) {
                            <li>
                                @if (!item.isShowIcon) {
                                    <a
                                        [routerLink]="item.link"
                                        (click)="close()"
                                        aria-label="Close menu">
                                        <img
                                            width="17"
                                            height="17"
                                            [ngSrc]="'navigation-arrow-back' | asset: assetPath"
                                            alt="Navigation arrow back" />
                                        <span class="h2-text uppercase full-white f-s-38">{{
                                            item.text
                                        }}</span>
                                    </a>
                                } @else if (innerWidth) {
                                    <div
                                        role="button"
                                        tabindex="0"
                                        class="dropdown"
                                        (keyup.enter)="showServiceInfo($event)"
                                        (click)="
                                            innerWidth <= screenSizeDesktop &&
                                                showServiceInfo($event)
                                        "
                                        (mouseenter)="
                                            innerWidth > screenSizeDesktop &&
                                                showServiceInfo($event)
                                        "
                                        (mouseleave)="
                                            innerWidth > screenSizeDesktop &&
                                                closeServiceInfo($event)
                                        ">
                                        <button
                                            class="dropbtn links"
                                            [attr.aria-label]="'service page' | anchorLabel">
                                            <div
                                                [style.backgroundImage]="
                                                    isShowServiceInfo()
                                                        ? ('navigation-arrow-back-yellow'
                                                          | asset: assetPath
                                                          | imageUrl)
                                                        : ('navigation-arrow-back'
                                                          | asset: assetPath
                                                          | imageUrl)
                                                "
                                                class="img"></div>
                                            <span
                                                [class.yellow]="isShowServiceInfo()"
                                                [class.full-white]="!isShowServiceInfo()"
                                                class="h2-text uppercase f-s-38">
                                                Service Page
                                            </span>
                                        </button>
                                        <div
                                            class="dropdown-content"
                                            [style.visibility]="isShowServiceInfo() | visibility"
                                            [style.height.px]="innerHeight"
                                            [style.right.px]="
                                                width()
                                                    | rightServicePage
                                                        : isShowServiceInfo()
                                                        : innerWidth
                                            "
                                            [style.width.px]="
                                                width() | widthServicePage: innerWidth
                                            ">
                                            <jsdaddy-service-page-info-menu
                                                #servicePageInfoMenu
                                                (closeSelf)="closeServiceInfo($event)"
                                                (closeParentMenu)="close($event)" />
                                        </div>
                                    </div>
                                }
                            </li>
                        }
                    </ul>
                </div>
                <div class="bottom-content">
                    <div class="social-network">
                        <a
                            href="https://www.linkedin.com/company/jsdaddy/"
                            target="_blank"
                            aria-label="Read more about JSDaddy on Linkedin">
                            <img
                                width="24"
                                height="24"
                                [ngSrc]="'linkedin-white-bg' | asset: assetPath"
                                alt="Linkedin icon" />
                        </a>
                    </div>
                    <div class="bg-plus-menu">
                        <img
                            width="97"
                            height="140"
                            [ngSrc]="'bg-plus-menu' | asset: assetPath"
                            alt="Plus menu background" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</jsdaddy-section-wrapper>
