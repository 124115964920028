<jsdaddy-header />
<div [ngClass]="['content', readyForDiscussClass()]">
    <router-outlet />
    <jsdaddy-footer [needCrankle]="needCrankle()!" />
</div>
<div
    #readyForDiscussContainer
    [style.visibility]="isShowReadyForDiscuss()! | visibility"
    [ngClass]="[
        'ready-for-discuss',
        readyForDiscussClass(),
        isShowReadyForDiscuss()! | rightContent: innerWidth(),
    ]">
    <jsdaddy-ready-for-discuss />
    <div
        class="blur"
        [class.show]="isShowReadyForDiscuss()"
        [class.hide]="!isShowReadyForDiscuss()"></div>
</div>
