@if (isOpened()) {
    <div (wheel)="$event.preventDefault()" class="container">
        @if (type()) {
            <div
                [class.error-border]="type() === errorType"
                [class.success-borde]="type() === successType"
                class="dialog white">
                <div>
                    @switch (type()) {
                        @case (successType) {
                            <div class="success column ai-c">
                                <img
                                    width="42"
                                    height="42"
                                    [ngSrc]="'success' | asset: assetPath"
                                    alt="success" />
                                <span>Your message was successfully sent!</span>
                                <ng-container *ngTemplateOutlet="confirmButton" />
                            </div>
                        }
                        @case (errorType) {
                            <div class="error column ai-c">
                                <img
                                    width="42"
                                    height="42"
                                    [ngSrc]="'error' | asset: assetPath"
                                    alt="error" />
                                <span
                                    >We were unable to deliver your message because the email
                                    address you provided does not exist. Please check the accuracy
                                    of the entered address and try sending the message again.</span
                                >
                                <ng-container *ngTemplateOutlet="confirmButton" />
                            </div>
                        }
                        @case (loadingType) {
                            <div class="loading column ai-c">
                                <img
                                    width="194"
                                    height="146"
                                    ngSrc="assets/images/shared/contact-form/loader.gif"
                                    alt="loading" />
                                <span>Sending Message...</span>
                            </div>
                        }
                    }
                </div>
            </div>
        }
    </div>
}
<ng-template #confirmButton>
    <jsdaddy-button justifyContent="center" [type]="buttonType" title="Confirm" (click)="close()" />
</ng-template>
