export const enum AppPath {
    EMPTY = '',
    CAREER = 'career',
    CAREER_DETAILS = ':slug',
    SERVICE = 'services/:slug',
    SERVICES = 'services',
    CONTACT_US = 'contact-us',
    CASE_STUDIES = 'case-studies',
    // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
    CASE = ':slug',
    ALL = '**',
}
