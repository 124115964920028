import {
    booleanAttribute,
    ChangeDetectionStrategy,
    Component,
    input,
    ViewEncapsulation,
} from '@angular/core';
import { HeaderTitleComponent } from '@shared/header-title/header-title.component';
import { ImageUrlPipe } from '@shared/image-url/image-url.pipe';
import { SectionWrapperComponent } from '@shared/section-wrapper/section-wrapper.component';
import { BreadcrumbsComponent } from '@shared/breadcrumbs/breadcrumbs.component';

@Component({
    selector: 'jsdaddy-header-image[title][asset]',
    standalone: true,
    imports: [ImageUrlPipe, HeaderTitleComponent, BreadcrumbsComponent, SectionWrapperComponent],
    templateUrl: './header-image.component.html',
    styleUrl: './header-image.component.scss',
    encapsulation: ViewEncapsulation.Emulated,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderImageComponent {
    public title = input.required<string | undefined>();
    public subtitle = input<string>();
    public asset = input.required<string>();
    public isSubtitleMoreThanText = input(false, {
        transform: booleanAttribute,
    });
}
